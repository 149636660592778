import React from "react";

import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

// import Features from '../components/Features'
import "../components/Styles/Homepage.scss";

import Layout from "../components/Layout";
import Testimonials from "../components/Testimonials";
import RegisterSection from "../components/Register";
import RecentBookings from "../components/RecentBookings";
// import CurrentTopics from '../components/CurrentTopics';

import ExpertsMap from "../components/Maps/ExpertsMap";

import arrowdown from "../img/arrow-down.png";
import Helmet from "react-helmet";

import ImageCarousel from "../components/ImageCarousel";
import ImageExpertsCarousel from "../components/ImageExpertsCarousel";

const scrollToComponent = require("react-scroll-to-component");

export const HomePageTemplate = ({
  heading,
  description,
  midBanner,
  featuredExperts,
  recentBookings,
  currentTopics,
  lowBanner,
  basicInfo,
  basicDescription,
  workProcess,
  testimonials,
  scrollHere,
}) => (
  <div>
    <section
      style={{ padding: "25px", textAlign: "center", overflow: "hidden" }}
    >
      <img
        className="animated infinite bounce2 slower animated-arrow"
        style={{ cursor: "pointer" }}
        alt="Globelynx"
        src={arrowdown}
        onClick={() =>
          scrollToComponent(scrollHere, {
            offset: 0,
            align: "top",
            duration: 1500,
          })
        }
      />
    </section>

    <div
      className="container-fluid"
      ref={(section) => {
        scrollHere = section;
      }}
    >
      <div className="row">
        <div className="has-text-centered has-padding">
          {/* <h1>{heading}</h1>
					<p>{description}</p> */}
          <h1>Find An Expert With Globelynx</h1>
          <p>
            Globelynx is a global platform, focused on elevating brands by
            facilitating press requests and connecting experts with journalists
            and broadcasters. We have a vetted and verified expert database for
            journalists, including underrepresented voices and thought leaders
            from a variety of backgrounds and disciplines. Add Globelynx to your
            arsenal and access experts in every industry when you need them
            most.
          </p>
        </div>
      </div>
    </div>

    <div className="is-fullwidth has-background-gray">
      <div
        className="container-fluid"
        ref={(section) => {
          scrollHere = section;
        }}
      >
        <div className="row">
          <div className="has-text-centered has-padding">
            {/* <h1>{heading}</h1>
					<p>{description}</p> */}
            <h1>Globelynx – The Home Of The Expert</h1>
            <h2>Expert Database For Journalists</h2>
            <p>
              Every day, the biggest global broadcasters use our platform to
              find an expert and enhance their news reporting.
            </p>
            <p>
              Globelynx have built the world’s largest community of highly
              qualified, TV ready experts. Our database of expert resources for
              journalists can add context, insight and a unique spark to a story
              or news piece.
            </p>

            <p>
              We have already done the hard work of connecting with industry
              experts for journalists and broadcasters to call upon. Globelynx
              facilitates press requests quickly as all our expert partners are
              provided with an easy-to-use ‘studio-in-a-box’ camera system and
              connection to the Globelynx network, delivering high quality HD
              video in broadcast format.
            </p>
            {/* <Link to="/about" className="button">Discover More</Link> */}

            <Link
              to="/contact"
              className="button is-dark"
              style={{ marginBottom: "20px" }}
            >
              JOIN THE COMMUNITY
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div
      className="container-fluid"
      ref={(section) => {
        scrollHere = section;
      }}
    >
      <div className="row">
        <div className="has-text-centered has-padding">
          <p>
            The Globelynx platform helps to accelerate the voice and message
            your company can have, with authoritative experts and thought
            leaders available to provide expert commentary on television and
            assist in all kinds of news reports.
          </p>

          <p>
            We fulfil press requests and develop exposure opportunities for
            institutions, businesses and individuals on global platforms and
            channels.
          </p>

          <b>Are you looking to expand your influence? </b>

          <br />
          <br />

          <b>Are you looking to find an expert that’s broadcast-ready? </b>

          <br />
          <br />

          <b>Connect with Globelynx! </b>

          <br />
          <br />

          <Link
            to="/contact"
            className="button is-dark"
            style={{ marginBottom: "20px" }}
          >
            JOIN THE COMMUNITY
          </Link>
        </div>
      </div>
    </div>

    <div className="is-fullwidth">
      <ImageCarousel />
      {/* <img src={`https://placehold.it/600x400`} style={{ width: '100%', height: 650 }} /> */}
      {/* <ExpertsMap /> */}
    </div>
    <div
      className="full-width-image-container"
      style={{
        backgroundImage: `url(${
          midBanner.childImageSharp
            ? midBanner.childImageSharp.fluid.src
            : midBanner
        })`,
      }}
    />
    {/* <div className=" is-fullwidth">
				<div className="container-fluid">
					<div className="row has-padding has-text-centered">
					<h1>RECENT TOPICS</h1>
					<CurrentTopics />
					</div>
				</div>
			</div> */}

    <div className="is-fullwidth">
      <div className="container-fluid">
        <div className="row">
          <div className="has-text-centered has-padding">
            <h2 className="custom-heading">Expert Partners</h2>
            <ImageExpertsCarousel />
          </div>
        </div>
      </div>
    </div>

    <div className="is-fullwidth">
      <div className="container-fluid">
        <div className="row">
          <div className="has-text-centered has-padding">
            {/* <h2 className="custom-heading">EXPERTS</h2> */}
            <RecentBookings />
          </div>
        </div>
      </div>
    </div>

    <div
      className="full-width-image-container"
      style={{
        backgroundImage: `url(${
          lowBanner.childImageSharp
            ? lowBanner.childImageSharp.fluid.src
            : lowBanner
        })`,
      }}
    />
    <div className="container-fluid">
      <div className="row">
        <div className="has-text-centered has-padding">
          {/* <h2> {basicInfo}</h2>
            <p>{basicDescription}</p> */}
          <h2>Give Your Organisation The Globelynx Advantage</h2>
          <p>
            Our 24/7 <b>Client Support Team</b> ensures that every expert you
            connect with is fully prepared for their interview – wired up for
            sound, framed, lit and focused professionally. Globelynx expert
            interviews allow you to control and customise your own environment
            from a subtly branded backdrop, to a trading floor or even a
            cityscape. The Globelynx service will elevate your report,
            emphasising professionalism but allowing your story to speak for
            itself!
            <br />
            <br />
            Our dedicated <b>Client Partnerships Team</b> work tirelessly on
            your behalf to deliver a bespoke press request and expert
            partnership service. Our experienced team facilitates the most
            relevant connections, helping broadcasters and journalists to find
            expert sources in a pinch, against a deadline and when it matters
            most. We have built our comprehensive expert database for
            journalists over years of operating in the industry. Our long term
            partnerships mean we are able to make the most appropriate and
            suitable connections to industry experts for our clients. <br />{" "}
            <br />
            The Client Partnerships Team will monitor the latest breaking
            stories around the clock and distribute targeted alerts, driving the
            news agenda to our clients. We will manage experts’ availability
            daily and then supply briefs to all relevant broadcasters and
            support the booking process.
            <br />
            <br />
            Our <b>Globelynx Cameras</b> are custom designed with the express
            aim of delivering perfect ‘down-the-line’ live interviews in
            broadcast-quality HD. We believe that technology should allow our
            experts to focus on their content rather than worrying about the
            quality and reliability of their set-up.
            <br />
            <br />
            <b> Globelynx Connect</b> is our high-quality mobile application,
            allowing flexible access to the Globelynx network.
          </p>

          <Link
            to="/contact"
            className="button is-dark"
            style={{ marginBottom: "20px" }}
          >
            Discover More
          </Link>
        </div>
      </div>
    </div>
    {/* <RegisterSection /> */}
    <Testimonials testimonials={testimonials} />
  </div>
);

HomePageTemplate.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  midBanner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  featuredExperts: PropTypes.shape({
    heading: PropTypes.string,
    experts: PropTypes.array,
  }),
  recentBookings: PropTypes.shape({
    heading: PropTypes.string,
    bookings: PropTypes.array,
  }),
  currentTopics: PropTypes.shape({
    heading: PropTypes.string,
    topics: PropTypes.array,
  }),
  lowBanner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  basicInfo: PropTypes.string,
  basicDescription: PropTypes.string,
  workProcess: PropTypes.shape({
    heading: PropTypes.string,
    works: PropTypes.array,
  }),
  testimonials: PropTypes.array,
  scrollHere: PropTypes.string,
};

const HomePage = ({ data }) => {
  const { frontmatter: home } = data.homePageData.edges[0].node;
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Expert Database For Journalists | Find An Expert For Journalists
        </title>
        <meta
          name="description"
          content="Connect journalists with broadcast & interview ready industry experts. Access a community of experts across all disciplines from our global platform. Join now!."
        />
        <meta
          property="og:title"
          content="Expert Database For Journalists | Find An Expert For Journalists"
        />
        <meta property="og:url" content="/" />
      </Helmet>
      <Layout location="homepage" election={true}>
        <HomePageTemplate
          heading={home.heading}
          description={home.description}
          midBanner={home.mid_banner}
          featuredExperts={home.featured_experts}
          recentBookings={home.recent_bookings}
          currentTopics={home.current_topics}
          lowBanner={home.low_banner}
          basicInfo={home.basic_info}
          basicDescription={home.basic_description}
          workProcess={home.work_process}
          testimonials={home.testimonials}
          scrollHere="scrollhere"
        />
      </Layout>
    </React.Fragment>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.object,
    }),
  }),
};

export default HomePage;

export const HomePageQuery = graphql`
  query {
    homePageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          html
          id
          frontmatter {
            heading
            description
            mid_banner {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featured_experts {
              experts {
                image {
                  childImageSharp {
                    fluid(maxWidth: 240, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                text
              }
              heading
              description
            }
            recent_bookings {
              bookings {
                image {
                  childImageSharp {
                    fluid(maxWidth: 240, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                text
              }
              heading
            }
            current_topics {
              topics {
                image {
                  childImageSharp {
                    fluid(maxWidth: 240, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                text
              }
              heading
            }
            low_banner {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            basic_info
            basic_description
            work_process {
              works {
                image {
                  childImageSharp {
                    fluid(maxWidth: 240, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                text
              }
              heading
            }
            testimonials {
              author
              quote
            }
          }
        }
      }
    }
  }
`;
