import React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

import Slider from "react-slick";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import defaultProfile from "../img/default-profile.png";

import elasticsearch from "elasticsearch";

import _ from "lodash";

const connectionString =
  "https://search-gl-experts-search-varfbfqqenkyp547xtgx3ttbwa.ap-southeast-2.es.amazonaws.com/";
const _index = "experts";
const _type = "_doc";

let client = new elasticsearch.Client({
  host: connectionString,
});

let picArr = [
  "https://s3-eu-west-1.amazonaws.com/globelynx-booking-portal-experts-compressed/public/hermes%20logo_18.jpg?itok=Wxbu_cUu",
  "https://s3-eu-west-1.amazonaws.com/globelynx-booking-portal-experts-compressed/public/Reading%20University.jpg",
  "https://s3-eu-west-1.amazonaws.com/globelynx-booking-portal-experts-compressed/public/Liverpool%20University.png?itok=1wr4m3jd",
  "https://s3-eu-west-1.amazonaws.com/globelynx-booking-portal-experts-compressed/public/Aberdeen%20Standard%20Investments_15.png?itok=uohbCyzY",
]

let apiURL = "https://api.globelynx.com/api/"


class ImageExpertsCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchResults: [],
    }

    this.getData = this.getData.bind(this)
  }

  getData() {
    var self = this

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(apiURL + "carousel", requestOptions)
      .then(response => response.json())
      .then(result => {
        result = result.sort((a, b) => (parseInt(a.carouselPosition) > parseInt(b.carouselPosition)) ? 1 : -1)
        self.setState({ searchResults: result })
      })
      .catch(error => console.log('error', error));
  }


  componentDidMount() {

    this.getData()
    // var self = this

    // client
    //   .search({
    //     index: _index,
    //     type: _type,
    //     size: 10,
    //     body: {
    //       query: {
    //         function_score: {
    //           query: { match_all: {} },
    //           random_score: {},
    //         },
    //       },
    //     },
    //   })
    //   .then(function (body) {

    //     self.setState({
    //       searchResults: body.hits.hits,
    //     });
    //   })

  }

  render() {


    var settings = {
      dots: false,
      infinite: true,
      speed: 3500,
      autoplay: true,
      autoplaySpeed: 3000,
      arrow: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      cssEase: 'linear',
      swipe: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        }
      ],
    };

    return (

      <div className="is-fullwidth">
        <div className="container-fluid">
          <div className="row">
            <div className="has-text-centered has-padding " >

              {
                this.state.searchResults.length < 3 ? "" :
                  <Slider {...settings}>
                    {
                      this.state.searchResults.map((v) => {
                        return (
                          <div className="image-carousel">
                            <div className="carousel-container">
                              <img
                                style={{
                                  // maxWidth: 130,
                                  // width: 'auto',
                                  // height: 100,
                                  // margin: '0 auto'

                                  // objectFit: 'cover',
                                  // width: '100%',
                                  // height: 130,
                                  objectFit: 'contain',
                                  width: 'auto',
                                  height: 100,
                                  margin: '0 auto'
                                }}
                                src={`https://s3-eu-west-1.amazonaws.com/globelynx-booking-portal-experts-compressed/public/${v.carouselContent}`}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = defaultProfile;
                                }}
                              />
                            </div>
                          </div>
                        )
                      })
                    }
                  </Slider>

              }

            </div>
          </div>
        </div>
      </div>
    );
  }
}

ImageExpertsCarousel.propTypes = {
  ImageExpertsCarousel: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
      position: PropTypes.string,
    })
  )
};

export default ImageExpertsCarousel;