import React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

import Slider from "react-slick";

import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";


import elasticsearch from "elasticsearch";

import _ from "lodash";

const connectionString =
  "https://search-gl-experts-search-varfbfqqenkyp547xtgx3ttbwa.ap-southeast-2.es.amazonaws.com/";
const _index = "experts";
const _type = "_doc";

let client = new elasticsearch.Client({
  host: connectionString,
});





class ImageCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchResults: [],
    }
  }


  componentDidMount() {

    var self = this

    client
      .search({
        index: _index,
        type: _type,
        size: 10,
        body: {
          query: {
            function_score: {
              query: { match_all: {} },
              random_score: {},
            },
          },
        },
      })
      .then(function (body) {

        console.log(body)
        self.setState({
          searchResults: body.hits.hits,
        });
      })

  }

  render() {


    var settings = {
      dots: false,
      infinite: true,
      speed: 3500,
      autoplay: true,
      autoplaySpeed: 3000,
      arrow: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      cssEase: 'linear',
      swipe: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        }
      ],
    };

    return (
      <StaticQuery
        query={graphql`
        query {
          AlJaz: file(relativePath: { eq: "AlJaz.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          BBC: file(relativePath: { eq: "BBC.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          BFM: file(relativePath: { eq: "BFM.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          Bloomberg: file(relativePath: { eq: "Bloomberg.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          CGTN: file(relativePath: { eq: "CGTN.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          CNBC: file(relativePath: { eq: "CNBC.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          CNN: file(relativePath: { eq: "CNN.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          Fox: file(relativePath: { eq: "Fox.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          ITN: file(relativePath: { eq: "ITN.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          ITV: file(relativePath: { eq: "ITV.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          SkyNews: file(relativePath: { eq: "SkyNews.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          TVNZ: file(relativePath: { eq: "TVNZ.png" }) {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
        `}
        render={data => (
          <div className="is-fullwidth has-background-gray">
            <div className="container-fluid">
              <div className="row">
                <div className="has-text-centered has-padding " >
                  <Slider {...settings}>

                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img alt={`AlJaz | Globelynx | Expert`} fixed={data.AlJaz.childImageSharp.fixed} />

                      </div>
                    </div>


                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.BBC.childImageSharp.fixed} alt={`BBC | Globelynx | Expert`} />


                      </div>
                    </div>



                    <div className="image-carousel">
                      <div className="carousel-container">


                        <Img fixed={data.BFM.childImageSharp.fixed} alt={`BFM | Globelynx | Expert`} />



                      </div>
                    </div>



                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.Bloomberg.childImageSharp.fixed} alt={`Bloomberg | Globelynx | Expert`} />

                      </div>
                    </div>




                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.CGTN.childImageSharp.fixed} alt={`CGTN | Globelynx | Expert`} />

                      </div>
                    </div>


                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.CNBC.childImageSharp.fixed} alt={`CNBC | Globelynx | Expert`} />

                      </div>
                    </div>





                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.CNN.childImageSharp.fixed} alt={`CNN | Globelynx | Expert`} />

                      </div>
                    </div>


                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.Fox.childImageSharp.fixed} alt={`Fox | Globelynx | Expert`} />

                      </div>
                    </div>





                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.ITN.childImageSharp.fixed} alt={`ITN | Globelynx | Expert`} />

                      </div>
                    </div>


                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.ITV.childImageSharp.fixed} alt={`ITV | Globelynx | Expert`} />

                      </div>
                    </div>



                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.SkyNews.childImageSharp.fixed} alt={`SkyNews | Globelynx | Expert`} />

                      </div>
                    </div>



                    <div className="image-carousel">
                      <div className="carousel-container">

                        <Img fixed={data.TVNZ.childImageSharp.fixed} alt={`TVNZ | Globelynx | Expert`} />

                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

ImageCarousel.propTypes = {
  ImageCarousel: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
      position: PropTypes.string,
    })
  )
};

export default ImageCarousel;